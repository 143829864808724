import {navInit} from './scripts/navigation';
import {slickInit} from './scripts/slickInit';
import {initMap} from './scripts/leaflet';
import {ytPlayerInit} from './scripts/ytPlayer';
import {faqCollapseInit} from './scripts/faqCollapse';
import {entryCardsInit} from './scripts/entryCards';
import {loginScriptInit} from './scripts/loginScript';
require('fslightbox');

navInit();
slickInit();
initMap();
loginScriptInit();

if (document.querySelector(".section-faq")) {
  faqCollapseInit();
}

if (document.querySelector(".player")) {
  ytPlayerInit();
}

if (document.querySelector(".section_entryCards")) {
  entryCardsInit();
}

jQuery(document).ready(function ( $ ) {
  // smooth scroll to ID
  $('a[href*="#"]').on('click', function (e) {
    e.preventDefault();

    $('html, body').animate({
      scrollTop: $($(this).attr('href')).offset().top - 140 // choose what you need
    }, 500, 'linear');
  });

});