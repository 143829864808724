let panels = document.querySelectorAll('.section-faq__question');

function startAction() {
    if(!this.parentNode.classList.contains('active')) {
        panels.forEach((panel)=> {
            panel.parentNode.classList.remove('active');
            jQuery(document).ready(function ($) {
                $(panel).parent().find('.section-faq__answer').slideUp();
             });
        });
        this.parentNode.classList.add('active');
        let ele = this;
        jQuery(document).ready(function ($) {
            $(ele).parent().find('.section-faq__answer').slideDown();
         });
    } else {
        this.parentNode.classList.remove('active');
        let ele = this;
        jQuery(document).ready(function ($) {
            $(ele).parent().find('.section-faq__answer').slideUp();
         });
    }
}

function eventInit() {
    panels.forEach((panel)=> {
        panel.addEventListener('click', startAction);
    })
}

export function faqCollapseInit() {
    eventInit();
}