let cardBoxes = document.querySelectorAll('.section_entryCards__singleCard');
let descriptions = document.querySelectorAll('.section_entryCards__singleDescription');
let setEntryCardsButton = document.querySelectorAll('.setEntryCard');

const runScroll = () => {
  jQuery(document).ready(function ( $ ) {
    // smooth scroll to ID
    $('html, body').animate({
        scrollTop: $('.section_entryCards__descriptions').offset().top - 120 // choose what you need
      }, 500, 'linear');
  });
}

// const clickCardButton = (cardButton) => {
//   const titleAttribute = cardButton.getAttribute('data-cardButton-id');
//   if (!cardButton.classList.contains('active')) {
//     cardBoxes.forEach((cardButton) => {
//       if (cardButton.classList.contains('active')) {
//         cardButton.classList.remove('active');
//       }
//     });
//     cardButton.classList.add('active');
//     setDescription(titleAttribute);
//   }
//   runScroll();
// }

const setDescription = (attributeID) => {
  descriptions.forEach((description) => {
    if (description.classList.contains('active')) {
      description.classList.remove('active');
    }
    if (description.getAttribute('data-description-id') == attributeID) {
      description.classList.add('active');

      runTabs(description);
      setInfoTab(description.getAttribute('data-description-id'), 0)
    }
  });
}

const runTabs = (description) => {
  const cardBoxes = description.querySelectorAll('.section_entryCards__singleDescriptionTitle');
  const tabs = description.querySelectorAll('.section_entryCards__singleDescriptionTab');

  cardBoxes.forEach((cardButton) => {
    cardButton.addEventListener('click', () => {
      if (!cardButton.classList.contains('active')) {
        cardBoxes.forEach((cardButton) => {
          if (cardButton.classList.contains('active')) {
            cardButton.classList.remove('active');
          }
        });

        cardButton.classList.add('active');
        tabs.forEach((tab) => {
          if (tab.classList.contains('active')) {
            tab.classList.remove('active');
          }
          if (tab.getAttribute('data-info-id') == cardButton.getAttribute('data-info-id')) {
            tab.classList.add('active');
          }
        });
      }
    });
  })
}

const runSetEntryCard = (item) => {
  const cardID = item.getAttribute('data-card-id');
  const infoID = item.getAttribute('data-info-id');

  setDescription(cardID);
  setInfoTab(cardID, infoID);
  setTitleActive(cardID);
  runScroll();
}

const setInfoTab = (cardID, infoID) => {
  const tabs = document.querySelectorAll('.section_entryCards__singleDescriptionTab');
  const cardBoxes = document.querySelectorAll('.section_entryCards__singleDescriptionTitle ');
  tabs.forEach((tab) => {
    if (tab.classList.contains('active')) {
      tab.classList.remove('active');
    }
    if (tab.getAttribute('data-info-id') == infoID && tab.getAttribute('data-description-id') == cardID) {
      tab.classList.add('active');
    }
  });
  cardBoxes.forEach((cardButton) => {
    if (cardButton.classList.contains('active')) {
      cardButton.classList.remove('active');
    }
    if (cardButton.getAttribute('data-info-id') == infoID && cardButton.getAttribute('data-description-id') == cardID) {
      cardButton.classList.add('active');
    }
  });
}

const setTitleActive = (cardID) => {
  cardBoxes.forEach((cardButton) => {
    if (cardButton.classList.contains('active')) {
      cardButton.classList.remove('active');
    }
    if (cardButton.getAttribute('data-card-id') == cardID) {
      cardButton.classList.add('active');
    }
  });
}


/**
 * run events
 */
function eventInit() {
  // cardBoxes.forEach((cardButton) => {
  //   cardButton.addEventListener('click', () => clickCardButton(cardButton));
  // })

  descriptions.forEach((description) => {
    if (description.classList.contains('active')) {
      runTabs(description);
    }
  })

  setEntryCardsButton.forEach((button) => {
    button.addEventListener('click', () => runSetEntryCard(button))
  })
}

export function entryCardsInit() {
  eventInit();
}