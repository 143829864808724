import $ from 'jquery';
window.$ = window.jQuery = $;
import 'slick-carousel';

export function slickInit() {
  jQuery(document).ready(function ($) {
    $(".section_banner_homesite__carousel").slick({
      infinite: true,
      // autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      dots: true,
      arrows: true,
      appendArrows:$('.slider-controls'),
      appendDots:$('.slider-controls'),
    });
  });
}