import 'leaflet';
import 'leaflet.markercluster';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import iconUrl from '../../../assets/img/pin.png';
// import 'leaflet/dist/images/marker-shadow.png';

export function initMap() {

    //settings of icon
    var greenIcon = L.icon({
        iconUrl: iconUrl,
    
        iconSize:     [32, 41], // size of the icon
        iconAnchor:   [16, 41], // point of the icon which will correspond to marker's location
        // shadowAnchor: [4, 62],  // the same for the shadow
        popupAnchor:  [0, -41] // point from which the popup should open relative to the iconAnchor
    });

    // position we will use later
    var lat = 52.377078;
    var lon = 16.984563;
    // initialize map
    var map = L.map('map-here').setView([lat, lon], 14);
    // set map tiles source
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
        maxZoom: 18,
    }).addTo(map);
    // add marker to the map
    var markera = L.marker([lat, lon], {icon: greenIcon}).addTo(map);
    // add popup to the marker
    markera.bindPopup("<b>WGRO SA</b>").openPopup(); 


    if (document.querySelector("#contactMap")) {
        var map2 = L.map('contactMap').setView([lat, lon], 14);
        // set map tiles source
        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
            maxZoom: 18,
        }).addTo(map2);
        // add marker to the map
        var markera2 = L.marker([lat, lon], {icon: greenIcon}).addTo(map2);
        // add popup to the marker
        markera2.bindPopup("<b>WGRO SA</b>").openPopup(); 
    }
    
}