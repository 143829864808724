const mainLoginButton = document.querySelector('.mainLogin');
const footerLoginButton = document.querySelector('.footerLogin');
const loginBox = document.querySelector('.login');

const closeButtonInit = () => {
  const closeButton = document.querySelector('.login__close');
  closeButton.addEventListener( 'click', () => loginBox.classList.remove('active') )
}

const triggerLoginBox = () => {
  if (loginBox.classList.contains('active')) {
    loginBox.classList.remove('active');
  } else {
    loginBox.classList.add('active');
    closeButtonInit();
  }
}

const eventInit = () => {
  if(mainLoginButton) mainLoginButton.addEventListener('click', () => triggerLoginBox());
  if(footerLoginButton) footerLoginButton.addEventListener('click', () => triggerLoginBox());
}

export const loginScriptInit = () => eventInit();