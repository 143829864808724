export const ytPlayerInit = () => {

  const playerList = document.querySelectorAll('.player');
  console.log(playerList);

  var tag = document.createElement('script');

  tag.src = "https://www.youtube.com/iframe_api";
  var firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  var player;
  window.onYouTubeIframeAPIReady = () => {
    console.log('all you need is movie')

    Array.from(playerList).map(element => {

      const videoID = element.getAttribute('id');
      console.log(videoID)
      player = new YT.Player(videoID, {
        // height: '360',
        // width: '640',
        videoId: videoID,
        playerVars: {
          rel: 0,
          controls: 1,
          showinfo: 0,
          autoplay: 0,
          loop: 0,
          modestbranding: 0
        },
        events: {
          'onReady': onPlayerReady,
        }
      });
    })
  }

  function onPlayerReady(event) {
    // event.target.mute();
    // event.target.playVideo();
  }
}